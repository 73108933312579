<template>
  <div class="card gutter-b">
    <div class="card-body">
      <b-table
        :items="movements"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        :sort-compare="myCompare"
      >
        <template #cell(created_at)="row">
          {{ new Date(row.item.created_at).toLocaleString() }}
        </template>
        <template #cell(inbound)="row">
          {{ row.item.type == "INBOUND" ? row.item.qty : "" }}
        </template>
        <template #cell(outbound)="row">
          {{ row.item.type == "OUTBOUND" ? row.item.qty : "" }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { getProductMovement } from "@/api/warehouse.api";

import { ref, onMounted } from "@vue/composition-api";
export default {
  name: "productMovement",
  setup(_, context) {
    const productId = context.root.$route.params.id;

    const movements = ref([]);
    onMounted(async () => {
      const response = await getProductMovement(productId);
      movements.value = response.data;
    });

    const myCompare = function(itemA, itemB, key) {
      if (key !== "created_at") {
        return false;
      } else {
        let a = new Date(itemA[key]);
        let b = new Date(itemB[key]);

        return a - b;
      }
    };

    return {
      movements,
      fields: [
        { key: "reference_no", label: "Reference No.", sortable: true },
        { key: "created_by", label: "Movement By" },
        { key: "created_at", label: "Movement Date/Time", sortable: true },
        { key: "inbound", label: "Inbound" },
        { key: "outbound", label: "Outbound" }
      ],
      sortBy: "created_at",
      sortDesc: true,
      myCompare
    };
  }
};
</script>
